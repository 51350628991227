import * as React from "react"
import PropTypes from "prop-types"

import "./layout.css"


const Layout = ({ children }) => {

  return (
      <div>
        <main>{children}</main>
        <footer>
          
        </footer>
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
