/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import Layout from "./src/components/layout";

import { ApolloProvider } from "@apollo/client"
import * as apollo from "@apollo/client"
import { InMemoryCache } from "@apollo/client/cache"
import fetch from 'isomorphic-fetch';

const createApolloClient = () => {
    const httpLink =
        new apollo.HttpLink({
            uri: "/api/graphql/",
            credentials: "include",
            fetch
        })
    return new apollo.ApolloClient({
        cache: new InMemoryCache(),
        link: httpLink,
    })
}

export const wrapRootElement = ({ element, ...restProps }, ...args) => {
    return (
        <ApolloProvider client={createApolloClient()}>
            <Layout
                name="wrapRootElement"
                props={restProps}
                args={args}
                mode="browser"
            >
                {element}
            </Layout>
        </ApolloProvider>
    )
}
